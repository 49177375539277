.logo {
    width: 100px;
    height: 100px;
    position: absolute;
    margin-left: 140px;
    margin-top: -76px;
}
.logo img {
    width: 100%;
    height: 100%;
}
.history {
    width: 332px;
    height: auto;
    overflow: hidden;
    border: 1px solid #999;
    position: absolute;
    background: white;
    z-index: 2;
}
.history li {
    height: 36px;
    cursor: pointer;
    line-height: 36px;
    padding-left: 20px;
    transition: background .2s, color .2s;
}
.history li:hover {
    background: #999;
    color: white;
    transition: background .2s, color .2s;
}
.container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    padding-top: 100px;

}
.container h2 {
    color: white;
}
.slideLeft {
    left: -200%;
    animation: slideMove .5s;
    animation-fill-mode: forwards;
    z-index: 9999;
}

@keyframes slideMove {
    0% {
        left: -200%;
    }
    100% {
        left: 0%;
    }
}